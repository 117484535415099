function onOwlCarouselReady(){
	const carouselDestaques = {
		children: $('#destaques').children().length,
		xs : 1,
		sm : 2,
		md : 3,
		lg : 4,
		xl : 4
	};

	$('#destaques').owlCarousel({
		autoplay: true,
		nav: true,
		navText: [
			'<i class="fas fa-chevron-left"></i>',
			'<i class="fas fa-chevron-right"></i>'
		],
		responsive : {
			0 : {
				items 	: carouselDestaques.xs,
				loop 	: carouselDestaques.children > carouselDestaques.xs 
			},
			500 : {
				items 	: carouselDestaques.sm,
				loop 	: carouselDestaques.children > carouselDestaques.sm,
				margin 	: 30 
			},
			768 : {
				items 	: carouselDestaques.md,
				loop 	: carouselDestaques.children > carouselDestaques.md,
				margin 	: 30 
			},
			922 : {
				items 	: carouselDestaques.lg,
				loop 	: carouselDestaques.children > carouselDestaques.lg,
				margin 	: 30 
			},
			1200 : {
				items 	: carouselDestaques.xl,
				loop 	: carouselDestaques.children > carouselDestaques.xl,
				margin 	: 30 
			}
		}
	});


	$('[data-owl]').click(function(e){
		e.preventDefault();

		let owl 	= $(this).data('owl');
		let target	= $(this).attr('href');

		$(target).trigger(owl+'.owl.carousel');
	});
}