let LazyImage = (function(){
	let jaImg 	= false;
	let isLazy 	= function(){
		$('lazyimage').each(function(){
			if(!this.loaded){
				if(this.getBoundingClientRect().top < (window.innerHeight + 200)){
					let src = $(this).attr('src') || '';
					let alt = $(this).attr('alt') || '';
					let classe = 'img-fluid';

					if(this.getAttribute('data-classe')){
						classe = classe + ' ' + this.getAttribute('data-classe');
					}

					let img = document.createElement('img');

					img.src = src;
					img.alt = alt;

					$(img).addClass(classe);

					if($(this).html() == ''){
						this.appendChild(img);
					}

					this.loaded = true;
				}
			}
		});
	}

	isLazy();

	$(window).scroll(function(){
		if (jaImg) return;

		setTimeout(function(){
			jaImg = false;
		}, 100);

		isLazy();
	});
})();