let affixTopo = (function(){
	let jaFixou = false;
	let fx = function(){
		if($(window).scrollTop() >= $('.bar-topo').outerHeight()){
			$('.topo').css('height', $('.topo').outerHeight());

			$('.nav-header').addClass('fx');
		}else{
			$('.nav-header').removeClass('fx');
			$('.topo').removeAttr('style');
		}
	}

	fx();

	$(window).scroll(function(){
		if(jaFixou) return;

		setTimeout(function(){
			jaFixou = false;
		}, 100);

		fx();
	})
})();