$('[data-toggle="input-type"]').click(function(e){
	e.preventDefault();

	let target 			= $(this).data("target");
	let type 			= $(this).data('type');
	let defaultType 	= $(this).data('default-type')

	let targetType 		= $(target).attr('type');

	switch(targetType){
		case type:
			$(target).attr('type', defaultType)
		break;
		case defaultType:
			$(target).attr('type', type);
		break;
	}

	$(this).toggleClass('active')
})