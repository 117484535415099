
/* CAROUSEL DE PRODUTOS DA INDEX*/
$('[data-toggle="carousel-content"]').click(function(event) {
	event.preventDefault();

	var arquivoToLoad = DOMINIO+'/assets/json/'+$(this).data('content')+'.json';
	
	jQuery.getJSON( arquivoToLoad, function(json, textStatus) {
		if(textStatus == 'success'){
	  		var template = $('#templateProduto').html();

	  		$('#produtos .carousel-inner').html('');

	  		for(var i=0; i<json.length; i++){
	  			var produto 	= template;
	  			var img 		= json[i].img; // {"src" : "...", "alt": "..."}
	  			var nome 		= json[i].nome; // Nome do produto
	  			var preco 		= json[i].preco; // {"preco" : {"de": "..", "por" : "..", "parcelas", ".."}}
	  			var detalhes 	= json[i].detalhes; // link do botão detalhes
	  			var comprar 	= json[i].comprar; // link do botão comprar

	  			produto = produto.replace(/{{image.src}}/g, img.src);
	  			produto = produto.replace(/{{image.alt}}/g, img.alt);
	  			produto = produto.replace(/{{nome}}/g, nome);
	  			produto = produto.replace(/{{de}}/g, preco.de);
	  			produto = produto.replace(/{{por}}/g, preco.por);
	  			produto = produto.replace(/{{parcelas}}/g, preco.parcelas);
	  			produto = produto.replace(/{{detalhes}}/g, detalhes);
	  			produto = produto.replace(/{{comprar}}/g, comprar);

	  			$('#produtos .carousel-inner').append(produto);

	  		}

	  		$('#produtos').responsiveBsCarousel();

	  		$(window).scrollTop($(window).scrollTop() + 1);

		}else{
			console.log(textStatus);
		}
	});
});
/* CAROUSEL DE PRODUTOS DA INDEX*/
