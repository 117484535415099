var topo = document.querySelector('.topo');

function ctxTopo () {
	var bg = document.createElement('div');

	$(bg).addClass('bg-ctx-menu');

	$(bg).click(function(){
		$(topo).removeClass('open-menu open-search');

		$('.bg-ctx-menu').fadeOut(600, function(){
			$(this).remove();
		});
	});

	topo.appendChild(bg);

	$(bg).fadeIn(600);
}

$('[data-toggle="menu"]').click(function(){
	if(midiaSize == 'xs' || midiaSize == 'sm' || midiaSize == 'md') {
		ctxTopo ();
		$('.topo').addClass('open-menu');
	}
});

$('[data-toggle="search"]').click(function(){
	if(midiaSize == 'xs' || midiaSize == 'sm' || midiaSize == 'md') {
		ctxTopo ();
		$('.topo').addClass('open-search');
	}
});

$('.btn-toggle-categorias').click(function(){
	let c = $('.todas-categorias');
	let b = $('body');

	if(midiaSize == 'xs' || midiaSize == 'sm' || midiaSize == 'md'){
		c.collapse('toggle');
	}

	if(midiaSize === 'xl') {
		b.toggleClass('show-categorias');
	}
});