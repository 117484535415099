let zoomElement = $('#zoom_03');

if(zoomElement.length > 0) {
	let scriptElevateZoom = document.createElement('script');

	scriptElevateZoom.src = 'https://cdn.jsdelivr.net/gh/igorlino/elevatezoom-plus@1.2.3/src/jquery.ez-plus.js';

	document.body.appendChild(scriptElevateZoom);

	scriptElevateZoom.addEventListener('load', function(){
		makeZoom();
		
	});

	function makeZoom(){
		$('#zoom_03').ezPlus({
            cursor: 'pointer',
			gallery: 'gallery_01',
            galleryActiveClass: "active",
            imageCrossfade: true,
            loadingIcon: "assets/images/loading.gif",
            tint: true,
            tintColour: '#000',
            tintOpacity: 0.65,
            responsive: true,
            zoomType: 'inner'
		});

		$('#zoom_03').bind('click', function(e){
			var ez = $('#zoom_03').data('ezPlus');
            ez.closeAll(); //NEW: This function force hides the lens, tint and window
            // $.fancyboxPlus(ez.getGalleryList());
            return false;
		});

		$('#gallery-1 a').click(function(e){
			e.preventDefault();
		})
	}

}
