$('.material-form input, .material-form textarea').each(function(){
	if($(this).val() != ''){
		$(this).parent().addClass('focus');
	}
});

$('.material-form input, .material-form textarea').focus(function(){
	$(this).parent().addClass('focus');
});

$('.material-form input, .material-form textarea').blur(function(){
	if($(this).val() == ''){
		$(this).parent().removeClass('focus');
	}
});