$('.filtro').each(function(){
	function normalizeString(string){
		return string.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
	}

	let self 		= $(this);
	let input 		= self.find('input[type="hidden"]');
	let options 	= self.find('.options li');
	let filtro		= self.find('input[name="filter"]');
	let label 		= self.find('label');

	filtro.keyup(function(){
		let filterKey = normalizeString($(this).val());

		options.each(function(){
			let option 	= $(this);
			let text 	= normalizeString(option.text());

			if(text.indexOf(filterKey) < 0){
				$(this).hide();
			}else{
				$(this).removeAttr('style')
			}
		});
	});

	options.click(function(){
		let value = $(this).data('value');
		let text  = $(this).text();

		options.removeClass('active');
		$(this).addClass('active');

		label.text(text);
		input.val(value);
		self.removeClass(show);
	});
});