function resetSearchFilter(){
	$('.input-group-lbls').remove();
	$('#catFilter').val('');
	$('.drop-filtro li').removeClass('active');
}


$('[data-toggle="filtro"]').click(function(){
	$('#filtro').toggleClass('active');
});


$('.drop-filtro li').click(function(){
	let value 	= $(this).data('value');
	let text 	= $(this).text();
	let lis 	= $('.drop-filtro li');

	let lbls = `<div class="input-group-lbls">
	${text} <i class="fa fa-times ml-2" onclick="resetSearchFilter()"></i>
	</div>`;

	$('.input-group-lbls').remove();

	$('#catFilter').val(value);

	$('#filtro').after(lbls)

	lis.removeClass('active');
	$(this).addClass('active');

	$('#filtro').removeClass('active');
});